
import Vue from "vue";

export default Vue.extend({
  name: "ProjectNewAttachmentCard",
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
});
