
import {
  attachments,
  IProjectAreaOfInterest,
  IProjectReportsAddRequest,
  IReportHeaderTemplate,
  notes,
  projects,
} from "@/api";
import { Actions } from "@/store/models";
import Vue from "vue";

export default Vue.extend({
  name: "ProjectNewReportDialog",
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      headerTemplates: [] as IReportHeaderTemplate[],
      areasOfInterest: [] as IProjectAreaOfInterest[],

      item: {
        name: "",
        project: { id: 0 },
        company: { id: 0 },
        areas_of_interests: [],
        report_header_template_id: null,
        report_header_json: {} as any,
      } as IProjectReportsAddRequest,
    };
  },
  computed: {
    companyId: function (): number {
      return this.$store.state.company.id;
    },
    projectId: function (): number {
      return Number(this.$route.params.id);
    },
    selectedHeader: function (): string {
      return (
        this.headerTemplates.find(
          (h) => h.id == this.item.report_header_template_id
        )?.name || "Select Header"
      );
    },
  },
  async created() {
    this.init();
  },
  methods: {
    async init() {
      this.areasOfInterest = (
        await projects.areaOfInterests.getAll(this.projectId)
      ).data;
      this.areasOfInterest.forEach(async (aoi, i) => {
        const results = (
          await attachments.projects.getAll(this.projectId, aoi.id)
        ).data;
        const note = (await notes.areaOfInterest.get(aoi.id)).data[0];
        if (results.length || note) {
          (aoi as any).display = true;
        } else {
          (aoi as any).display = false;
        }
        this.$forceUpdate();
      });

      this.getHeaderTemplates();
      this.setupItem();
    },
    setupItem() {
      this.item = {
        name: "",
        project: { id: 0 },
        company: { id: 0 },
        areas_of_interests: [],
        // gpr_data_allowed: true,
        // sites_data_allowed: true,
        report_header_template_id: null,
        report_header_json: {} as any,
      } as IProjectReportsAddRequest;
    },
    async getHeaderTemplates() {
      this.headerTemplates = (
        await projects.reportHeaderTemplates.getAll()
      ).data;
    },
    async submit() {
      if (!this.item.areas_of_interests.length || !this.item.name) {
        this.$store.dispatch(
          Actions.DisplayError,
          "Please fill in required details."
        );
        return;
      }
      try {
        this.item.project.id = this.projectId;
        this.item.company.id = this.companyId;
        const template = this.headerTemplates.find(
          (t) => t.id === this.item.report_header_template_id
        )!;
        this.item.report_header_json = {
          name: template?.name,
          company_name: template?.company_name,
          domain_name: template?.domain_name,
          address: template?.address,
          email: template?.email,
          disclaimer: template?.disclaimer,
          phone: template?.phone,
        };

        await this.$emit("add", this.item);
        this.setupItem();
        this.$emit("toggleDialog", false);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.init();
      }
    },
  },
});
