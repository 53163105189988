
import Vue from "vue";
import ProjectNewAttachmentDialog from "@/components/project/attachments/ProjectNewAttachmentDialog.vue";
import ProjectAttachmentCard from "@/components/project/attachments/ProjectAttachmentCard.vue";
import ProjectNewAttachmentCard from "@/components/project/attachments/ProjectNewAttachmentCard.vue";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";
import { attachments, IAttachment } from "@/api";
import { Actions } from "@/store/models";
import ProjectMoveAttachmentDialog from "./ProjectMoveAttachmentDialog.vue";

export default Vue.extend({
  name: "ProjectAttachments",
  components: {
    ProjectNewAttachmentDialog,
    ProjectAttachmentCard,
    ProjectNewAttachmentCard,
    ConfirmationDialog,
    ProjectMoveAttachmentDialog,
  },
  props: {
    project: Object,
    selectAOI: Object,
    isLocked: Boolean,
  },
  data() {
    return {
      addAttachmentDialog: false,
      deleteResultDialog: false,
      moveAttachemntDialog: false,
      items: [] as IAttachment[],
      selectedItems: [] as IAttachment[],
      selected: new Set<number>(),
    };
  },
  computed: {
    companyId: function (): number {
      return Number(this.$store.state.company.id);
    },
    resultsByAreaOfInterest: function (): IAttachment[] {
      return this.items;
    },
    showable: function (): boolean {
      // not possible since we get items by selected AOI
      // if (this.isLocked && !this.items.length) {
      //   return false
      // }
      return true;
    },
  },
  created() {
    this.getAll();
  },
  methods: {
    // dialogs
    toggleAddAttachmentDialog(dialog: boolean) {
      this.addAttachmentDialog = dialog;
    },
    toggleDeleteResultDialog(dialog: boolean) {
      this.deleteResultDialog = dialog;
    },
    // attachments
    select(id: number) {
      if (!this.selected.delete(id)) {
        this.selected.add(id);
      }
      this.$forceUpdate();
    },
    async getAll() {
      if (!this.project?.id || !this.selectAOI?.id) {
        return;
      }
      try {
        this.items = (
          await attachments.projects.getAll(this.project.id, this.selectAOI.id)
        ).data;
        await this.$store.dispatch(Actions.GetCompanyAccount);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async add(item: any) {
      try {
        await attachments.projects.add(this.project.id, {
          ...item,
          projects_areas_of_interest_name: this.selectAOI.name,
        });
        this.getAll();
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async download() {
      for (let id of this.selected) {
        try {
          await attachments.downloadFromPresignedUrl(id);
        } catch (e) {
          this.$store.dispatch(Actions.DisplayError, e);
        }
      }
    },
    async move(name: string) {
      try {
        const fns = [] as any;
        this.selected.forEach((id) => {
          fns.push(
            attachments.update(id, {
              projects_areas_of_interest_name: name,
            })
          );
        });
        await Promise.all(fns);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      } finally {
        this.getAll();
        this.selected.clear();
      }
    },
    async confirmRemove() {
      if (!this.selected.size) {
        return;
      }
      this.deleteResultDialog = true;
    },
    async remove() {
      try {
        const fns = [] as any;
        this.selected.forEach((id) => {
          fns.push(attachments.delete(id));
        });
        await Promise.all(fns);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      } finally {
        this.getAll();
        this.selected.clear();
      }
    },
    async update(id: number, request: any) {
      try {
        await attachments.update(id, request);
        this.getAll();
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async reorder(ids: number[]) {
      try {
        await attachments.reorder(this.project.id, ids);
        this.getAll();
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    moveLeft() {
      const current = this.resultsByAreaOfInterest.find(
        (item) => item.id === this.selected.values().next().value
      ) as IAttachment;
      const index = this.resultsByAreaOfInterest.findIndex(
        (item) => item.id === current.id
      );
      if (index === 0) {
        this.$store.dispatch(
          Actions.DisplayError,
          "Cannot move this item to left."
        );
        return;
      }

      const prevItem = this.resultsByAreaOfInterest[index - 1];
      const ids = [] as number[];
      this.items.forEach((item) => {
        if (item.id === prevItem.id) {
          ids.push(current.id);
        } else if (item.id === current.id) {
          ids.push(prevItem.id);
        } else {
          ids.push(item.id);
        }
      });
      this.reorder(ids);
    },
    moveRight() {
      const current = this.resultsByAreaOfInterest.find(
        (item) => item.id === this.selected.values().next().value
      ) as IAttachment;
      const index = this.resultsByAreaOfInterest.findIndex(
        (item) => item.id === current.id
      );
      if (index === this.resultsByAreaOfInterest.length - 1) {
        this.$store.dispatch(
          Actions.DisplayError,
          "Cannot move this item to right."
        );
        return;
      }

      const nextItem = this.resultsByAreaOfInterest[index + 1];
      const ids = [] as number[];
      this.items.forEach((item) => {
        if (item.id === nextItem.id) {
          ids.push(current.id);
        } else if (item.id === current.id) {
          ids.push(nextItem.id);
        } else {
          ids.push(item.id);
        }
      });
      this.reorder(ids);
    },
  },
  watch: {
    selectAOI: {
      deep: true,
      handler() {
        this.getAll();
      },
    },
  },
});
