
import { IProject, IProjectAreaOfInterest, IScan, projects } from "@/api";
import Vue from "vue";
import EditableLabel from "@/components/common/EditableLabel.vue";
import AssignGroups from "@/components/common/AssignGroups.vue";
import ProjectNotes from "@/components/project/ProjectNotes.vue";
import ProjectAreaOfInterestSelect from "@/components/project/ProjectAreaOfInterestSelect.vue";
import ProjectScans from "@/components/project/scans/ProjectScans.vue";
import ProjectAttachments from "@/components/project/attachments/ProjectAttachments.vue";
import ProjectAreaOfInterestNotes from "@/components/project/ProjectAreaOfInterestNotes.vue";
import ProjectReports from "@/components/project/reports/ProjectReports.vue";
import ProjectAddressDialog from "@/components/project/ProjectAddressDialog.vue";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";
import { getDate, sizeInMB } from "@/utils";
import { Actions } from "@/store/models";
import BackButton from "@/components/common/BackButton.vue";
import { permissions } from "@/mixins";
import CompanyHeader from "@/components/common/gssiAdmin/CompanyHeader.vue";
import ProjectAddAreaDialog from "@/components/project/ProjectAddAreaDialog.vue";
import { createOrUpdateEmpty } from "ol/extent";

export default Vue.extend({
  name: "Project",
  components: {
    EditableLabel,
    AssignGroups,
    ProjectNotes,
    // ProjectAreaOfInterestSelect,
    // ProjectScans,
    ProjectAttachments,
    ProjectAreaOfInterestNotes,
    ProjectReports,
    ProjectAddressDialog,
    ConfirmationDialog,
    BackButton,
    CompanyHeader,
    ProjectAddAreaDialog,
  },
  mixins: [permissions],
  data() {
    return {
      project: {} as IProject,
      selectedGroups: [] as number[],
      areasOfInterest: [] as IProjectAreaOfInterest[],
      selectedAreaOfInterest: {} as IProjectAreaOfInterest & { index: number },
      scans: [] as IScan[],
      addressDialog: false,
      deleteProjectDialog: false,
      deleteAOIDialog: false,
      addAreaDialog: false,
      showAOIContainer: true,
      reportId: ""
    };
  },
  computed: {
    id: function (): number {
      return Number(this.$route.params.id);
    },
    isLocked: function (): boolean {
      return this.project?.status === 0 || (this as any).isGssiAdmin;
    },
    projectStatus: function (): any[] {
      return [
        {
          label: "Project Size",
          text: sizeInMB(this.project.size),
          icon: "mdi-database",
        },
        {
          label: "Last Updated",
          text: getDate(
            this.project.updated_datetime || this.project.created_datetime
          ),
          icon: "mdi-calendar-month",
          italic: true,
        },
      ];
    },
  },
  created() {
    this.getProject();
    this.getAreasOfInterest();
  },
  beforeDestroy() {
    this.$store.dispatch(Actions.GetCompanyProjects);
  },
  methods: {
    toggleAddressDialog(val: boolean) {
      if (this.isLocked) {
        return
      }
      this.addressDialog = val;
    },
    toggleDeleteProjectDialog(val: boolean) {
      this.deleteProjectDialog = val;
    },

    // project
    async getProject() {
      try {
        this.project = (await projects.get(this.id)).data[0];
        this.selectedGroups = this.project.projects_associate_groups.map(
          (g) => g.associate_group.id
        );
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
      setTimeout(async () => {
        if (this.isLocked) {
          if (
            !(this.$refs.attachments as any)?.showable &&
            !(this.$refs.aoiNotes as any)?.showable
          ) {
            this.showAOIContainer = false;
          }

          // get report id
          this.getReportId()
        }
      }, 500);
    },
    async getReportId() {
      try {
        let reports = (await projects.reports.getByCompany(this.project.company.id)).data;
        reports = reports.filter(r => r.project.id == this.project.id)
        this.reportId = reports[0]?.id?.toString() 
      } catch(e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async updateGroups(value: number[]) {
      try {
        await projects.update(this.id, {
          associates_groups: value?.length ? value : (null as any),
        });
        // todo: remove timeout when API issue is fixed
        setTimeout(() => {
          this.getProject();
        }, 1000);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async updateName(value: string) {
      try {
        this.project = (
          await projects.update(this.id, {
            name: value,
          })
        ).data;
        console.log((this.project as any).test.test)
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async updateCustomerName() {
      try {
        this.project = (
          await projects.update(this.id, {
            customer_name: this.project.customer_name,
          })
        ).data;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async updateJobCode() {
      try {
        this.project = (
          await projects.update(this.id, {
            job_code: this.project.job_code,
          })
        ).data;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async updateAddress(value: string) {
      try {
        this.project = (
          await projects.update(this.id, {
            address: value,
          })
        ).data;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async updateStatus() {
      try {
        this.project = (
          await projects.update(this.id, {
            status: this.isLocked ? 1 : 0,
          })
        ).data;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async deleteProject() {
      try {
        await projects.delete(this.id);
        this.$router.push("/projects");
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },

    // area of interest
    async getAreasOfInterest() {
      try {
        this.areasOfInterest = (
          await projects.areaOfInterests.getAll(this.id)
        ).data;
        if (!this.selectedAreaOfInterest.id) {
          this.selectedAreaOfInterest =
            { ...this.areasOfInterest[0], index: 0 } || {};
        }
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async updateAreaOfInterest(name: string) {
      try {
        const response = (
          await projects.areaOfInterests.update(this.selectedAreaOfInterest.id, {
            name,
          })
        ).data;
        this.selectedAreaOfInterest = {
          ...this.selectedAreaOfInterest,
          ...response,
        };
        this.getAreasOfInterest();
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async addAreaOfInterest(name: string) {
      try {
        await projects.areaOfInterests.add({
          name,
          project: {
            id: this.project.id,
          },
        });
        this.getAreasOfInterest();
        this.$store.dispatch(Actions.GetCompanyProjects);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async deleteAreaOfInterest() {
      try {
        await projects.areaOfInterests.delete(this.selectedAreaOfInterest.id);
        this.selectedAreaOfInterest = {} as any;
        this.getAreasOfInterest();
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    prevArea() {
      try {
        let index = this.selectedAreaOfInterest.index - 1;
        if (index < 0) {
          index = this.areasOfInterest.length - 1;
        }
        this.selectedAreaOfInterest = { ...this.areasOfInterest[index], index };
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    nextArea() {
      try {
        let index = this.selectedAreaOfInterest.index + 1;
        if (index === this.areasOfInterest.length) {
          index = 0;
        }
        this.selectedAreaOfInterest = { ...this.areasOfInterest[index], index };
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },

    // scans
    scanAdded() {
      (this.$refs.attachments as any).getAll();
      (this.$refs.scans as any).getScans();
    },
    // scans & results -> moved to component level
    // move() {
    //   if (this.$refs.scans.selectedScans.size) {
    //     this.$refs.scans.toggleMoveScanDialog(true);
    //   }
    // },
    // download() {
    //   this.$refs.scans.download();
    //   this.$refs.attachments.download();
    // },
    // remove() {
    //   this.$refs.scans.confirmRemove();
    //   this.$refs.attachments.confirmRemove();
    // },
  },
  watch: {
    project: {
      deep: true,
      handler(p: IProject) {
        // console.log(this.selectedGroups);
      },
    },
    selectedAreaOfInterest: {
      deep: true,
      handler(val) {
        // console.log('project view: ', val.id, ' | ', val.name)
      },
    },
  },
});
