
import { IProjectAreaOfInterest } from "@/api";
import { Actions } from "@/store/models";
import Vue from "vue";

export default Vue.extend({
  name: "ProjectAddAreaDialog",
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      name: "",
    };
  },
  computed: {},
  methods: {
    async create() {
      if (!this.name) {
        this.$store.dispatch(
          Actions.DisplayError,
          "Please enter required fields."
        );
        return;
      }
      await this.$emit("create", this.name);
      this.closeDialog();
    },
    closeDialog() {
      this.$emit("update:dialog", false);
      this.name = "";
    },
  },
  watch: {
    selected: {
      deep: true,
      handler(val) {
        // console.log(val)
      },
    },
  },
});
