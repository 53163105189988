
import { Actions } from "@/store/models";
import Vue from "vue";
import GmapAddressAutocomplete from "@/components/common/GmapAddressAutocomplete.vue";

export default Vue.extend({
  components: { GmapAddressAutocomplete },
  name: "ProjectAddressDialog",
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      address: "",
    };
  },
  computed: {},
  methods: {
    async update() {
      try {
        await this.$emit("update", this.address);
        this.$emit("toggleDialog", false);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        setTimeout(() => {
          const addressComponent = document.getElementById("addressbar");
          const input = addressComponent!.querySelectorAll("input")[0];
          input.focus();
        });
      }
    },
  },
});
