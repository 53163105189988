
import { IProject, IProjectAreaOfInterest, projects, scans } from "@/api";
import { Actions } from "@/store/models";
import Vue from "vue";

export default Vue.extend({
  name: "ProjectMoveAttachmentDialog",
  props: {
    dialog: Boolean,
    currentAOI: Object,
  },
  data() {
    return {
      project: {} as IProject,
      areasOfInterest: [] as IProjectAreaOfInterest[],
      areaOfInterest: {} as IProjectAreaOfInterest,
    };
  },
  computed: {
    companyId: function (): number {
      return Number(this.$store.state.company.id);
    },
    projectId: function (): number {
      return Number(this.$route.params.id);
    },
  },
  created() {
    this.getAOIs();
  },
  methods: {
    async getAOIs() {
      try {
        this.areasOfInterest = (
          await projects.areaOfInterests.getAll(this.projectId)
        ).data;
        this.areasOfInterest = this.areasOfInterest.filter(
          (a) => a.id !== this.currentAOI.id
        );
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async precheck() {
      if (!this.areaOfInterest.id) {
        this.$store.dispatch(
          Actions.DisplayError,
          "Please fill in required details."
        );
        return;
      }
      this.move();
    },
    async move() {
      try {
        this.$emit("move", this.areaOfInterest.name);
        this.close();
        this.areaOfInterest = {} as IProjectAreaOfInterest;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    close() {
      this.$emit("update:dialog", false);
    },
  },
  watch: {
    dialog() {
      if (this.dialog === true) {
        this.getAOIs();
      }
    },
  },
});
