
import Vue from "vue";
import { sizeInMB } from "@/utils";

export default Vue.extend({
  name: "ProjectReportCard",
  components: {},
  props: {
    item: Object,
    isLocked: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    size: function (): string {
      return sizeInMB(this.item.size);
    },
  },
  methods: {
    goToReport() {
      this.$router.push("/reports/" + this.item.id);
    },
  },
});
